<template>
    <ion-page>
        <ion-tabs>
			<ion-header
				:class="{ 'header_hidden': !isVisiblePWAButton }"
				class="header-desktop-maps"
			>
				<ion-toolbar class="header-toolbar">
					<h3 class="header-title">
						Карта достопримечательностей в Горноуральском городском округе
					</h3>

					<ion-buttons
						v-if="isVisiblePWAButton"
						slot="end"
					>
						<ion-button
							color="primary"
							fill="solid"
							@click="installPWA"
						>
							Установить приложение
						</ion-button>
					</ion-buttons>
				</ion-toolbar>
			</ion-header>

            <ion-router-outlet/>

            <ion-tab-bar slot="bottom">
                <ion-tab-button
                    href="/info/places"
                    tab="places"
                >
                    <ion-icon name="prism"/>
                    <ion-label>Достопримечательности</ion-label>
                </ion-tab-button>
                <ion-tab-button
                    href="/info/routes"
                    tab="routes"
                >
                    <ion-icon name="analytics"/>
                    <ion-label>Маршруты</ion-label>
                </ion-tab-button>
            </ion-tab-bar>
        </ion-tabs>
    </ion-page>
</template>

<script>
import {
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonLabel,
    IonIcon,
    IonPage,
    IonRouterOutlet,
	IonButtons
} from '@ionic/vue';
import {
    square,
} from 'ionicons/icons';
import platform from 'platform';

export default {
    name:       'MainTabsLayout',
    components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet, IonButtons },
    setup() {
        return {
            square,
        };
    },
	data() {
		return {
			deferredPrompt: null,
		};
	},
	computed: {
		isChromeBrowser() {
			return platform.name === 'Chrome';
		},
		isYandexBrowser() {
			return platform.name === 'Yandex Browser';
		},
		isSafariBrowser() {
			return platform.name === 'Safari';
		},
		isMacOS() {
			return /Macintosh|MacIntel/.test(navigator.platform);
		},
		isIOSPlatform() {
			return /iPad|iPhone|iPod/.test(navigator.platform);
		},
		isVisiblePWAButton() {
			return this.deferredPrompt
				|| (this.isMacOS && this.isSafariBrowser)
				|| (this.isIOSPlatform && (this.isSafariBrowser || this.isChromeBrowser || this.isYandexBrowser));
		},
	},
	methods: {
		installPWA() {
			if (this.deferredPrompt) {
				this.deferredPrompt.prompt();
				this.deferredPrompt.userChoice.then((choiceResult) => {
					this.deferredPrompt = null;
				});
			}
			else if (this.isMacOS && this.isSafariBrowser) {
				window.open('/docs/Инструкция_Mac_Safari.pdf');
			}
			else if (this.isIOSPlatform && this.isSafariBrowser) {
				window.open('/docs/Инструкция_Iphone_Safari.pdf');
			}
			else if (this.isIOSPlatform && this.isChromeBrowser) {
				window.open('/docs/Инструкция_Iphone_Chrome.pdf');
			}
			else if (this.isIOSPlatform && this.isYandexBrowser) {
				window.open('/docs/Инструкция_Iphone_Yandex.pdf');
			}
		},
		checkCanInstallPWA() {
			if ('serviceWorker' in navigator && 'PushManager' in window) {
				window.addEventListener('load', () => {
					navigator.serviceWorker.register('/service-worker.js').then((registration) => {
						console.log('ServiceWorker зарегистрирован с областью видимости:', registration.scope);
					}).catch((error) => {
						console.error('Ошибка регистрации ServiceWorker:', error);
					});
				});

				window.addEventListener('beforeinstallprompt', (e) => {
					e.preventDefault();
					this.deferredPrompt = e;
				});

				window.addEventListener('appinstalled', (event) => {
					this.deferredPrompt = null;
				});
			}
		},
		removeCheckCanInstallPWA() {
			window.removeEventListener('load', () => {});
			window.removeEventListener('beforeinstallprompt', () => {});
			window.removeEventListener('appinstalled', () => {});
		},
	},
	created() {
		this.checkCanInstallPWA();
	},
	beforeUnmount() {
		this.removeCheckCanInstallPWA();
	},
};

</script>

<style scoped>
.header-toolbar {
	padding: 		 0 15px;
	display: 		 flex;
	justify-content: space-between;
	align-items: 	 center;
}
.header_hidden, .header-title {
	display: none;
}
.header-title {
	margin: 0;
}
@media (min-width: 1024px) {
	.header_hidden, .header-title {
		display: block;
	}
}
</style>
